const gtxConstants = require('@getrix/common/js/gtx-constants');
const gtxLoggedUser = require('@getrix/common/js/gtx-logged-user');
const $ = require('jquery');
const AskHelpView = require('@getrix/common/js/ask-help');
const gtm = require('@getrix/common/js/gtm');
const beamer = require('@getrix/common/js/beamer');
const appcues = require('@getrix/common/js/appcues');
const mixpanel = require('lib/mixpanel');
const Sentry = require('@sentry/browser');
const braze = require('@braze/web-sdk');
// Polyfills
require('../../commons/polyfills/fill');
require('../../commons/polyfills/fromEntries');
require('../../commons/polyfills/toBlob');

const salesforceOfflineFormEnabled = gtxConstants('SALESFORCE_OFFLINE_FORM_ENABLED') === 1;
const salesforceLiveChatEnabled = gtxConstants('SALESFORCE_LIVE_CHAT_ENABLED') === 1;

require('@getrix/common/js/main');
require('@getrix/common/js/xhr-wrapper');
require('@getrix/common/js/fetch-wrapper');

// eslint-disable-next-line no-undef
if (globalVars.SENTRY_ENABLED) {
    Sentry.init({
        // eslint-disable-next-line no-undef
        dsn: SENTRY_DSN,
        debug: true,
        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        // eslint-disable-next-line no-undef
        release: SENTRY_VERSION,
        // eslint-disable-next-line no-undef
        environment: globalVars.SENTRY_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const checkIsLoggedPage =
    gtxLoggedUser('idAgente') &&
    window.location.href.indexOf('/login/a2f') === -1 &&
    window.location.href.indexOf('/check-status/tos') === -1;

function mailXhr(url, data) {
    return $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
    });
}

let askHelp = salesforceLiveChatEnabled
    ? null
    : new AskHelpView({
          submitAction: mailXhr,
          external: salesforceOfflineFormEnabled ? 'salesforce' : null,
      });

if (!salesforceLiveChatEnabled) {
    askHelp.initialize($('.gtx-ask-help'));
}

gtm.loadGTM({
    // eslint-disable-next-line no-undef
    id: globalVars.GTM_ID,
});

if (gtxConstants('APPCUES_ENABLED') === '1' && checkIsLoggedPage) {
    appcues.appCuesInit();
}

if (gtxConstants('BEAMER_NEWS') === '1' && checkIsLoggedPage) {
    beamer.beamerInit();
}

// Identify univocally a user into Dynatrace RUMS
window.addEventListener('load', () => {
    if (!checkIsLoggedPage || gtxConstants('DYNATRACE_ENABLED') === 0 || typeof window.dtrum === 'undefined') {
        return;
    }

    window.dtrum.identifyUser(gtxLoggedUser('idAgente'));
});

mixpanel.init();

// Initialize Braze SDK
if (
    (Number(gtxConstants('BRAZE_IN_APP_MESSAGES')) || Number(gtxConstants('BRAZE_PUSH_NOTIFICATIONS'))) &&
    checkIsLoggedPage
) {
    const isLoggingEnabled = () => {
        const logEnabledUsers = gtxConstants('BRAZE_LOGGING_USERS')
            .split(',')
            .filter((item) => item);

        if (Number(gtxConstants('BRAZE_LOGGING')) === 1 && logEnabledUsers.length === 0) {
            return true;
        }

        if (Number(gtxConstants('BRAZE_LOGGING')) === 1 && logEnabledUsers.length > 0) {
            return logEnabledUsers.indexOf(gtxLoggedUser('agencyId')) !== -1;
        }

        return false;
    };

    const brazeConfigs = {
        baseUrl: gtxConstants('BRAZE_SDK_ENDPOINT'),
        allowUserSuppliedJavascript: true,
        enableLogging: isLoggingEnabled(),
    };

    if (Number(gtxConstants('BRAZE_PUSH_NOTIFICATIONS'))) {
        brazeConfigs.serviceWorkerLocation = '/braze-service-worker.js';
    }

    braze.initialize(gtxConstants('BRAZE_API_KEY'), brazeConfigs);
    braze.changeUser(gtxLoggedUser('agentUuid'));

    if (Number(gtxConstants('BRAZE_IN_APP_MESSAGES'))) {
        braze.automaticallyShowInAppMessages();
    }

    braze.openSession();
}
